<template lang="pug">
.alert.alert-danger.text-center.mb-0.p-0(role="alert")
  .box-center.d-flex.justify-content-center
    .icon
      UilExclamationTriangle
    .text(v-if="!isGracePeriodExpired")
      span {{ $t('priceChangeAlert.gracePeriodForPriceChange.line1') }}
    .text(v-else-if="hasFuturePackage")
      span {{ $t('priceChangeAlert.gracePeriodForPriceChangeExpiredForUserWithFuturePackage.line1') }}
    .text(v-else)
      span {{ $t('priceChangeAlert.gracePeriodForPriceChangeExpired.line1') }}
    .button(v-if="!hasFuturePackage")
      om-button(primary :loading="isActionInProgress" @click="redirectToPlanSettings") {{ $t('priceChangeAlert.gracePeriodForPriceChange.redirect') }}
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: {
      UilExclamationTriangle,
    },
    computed: {
      ...paymentGetters(['isGracePeriodExpired', 'hasFuturePackage']),
      isActionInProgress() {
        if (this.$route.query.updateRecurringPlan) return true;
        return false;
      },
    },
    methods: {
      redirectToPlanSettings() {
        this.$router.replace({
          name: 'plan_settings',
          query: { updateRecurringPlan: true },
        });
      },
    },
  };
</script>
